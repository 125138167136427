
//
// Usage:
//
// <a href="/path/to/somewhere"
//    data-method="delete"
//    data-coorum-confirm="[メッセージ（必須）]"
//    data-coorum-confirm-title="[ダイヤログタイトル（指定なければ非表示）]"
//    data-coorum-confirm-accept="[メインボタンのテキスト（指定なければOK）]"
//    data-coorum-confirm-reject="[サブボタンのテキスト（指定なければキャンセル）]">
//   ボタンテキスト
// </a>
//
export default class Confirm {
  constructor() {
    Confirm.setEvents($)
  }

  static setEvents($scope) {
    const $confirmTargets = $("[data-coorum-confirm]", $scope) // coorum-confirmが設定されているaタグ
    if ($confirmTargets.length < 1) return

    $confirmTargets.each((i, elem) => {
      elem.addEventListener("click", (event) => {
        Confirm.confirm({
          message: elem.dataset.coorumConfirm,
          title: elem.dataset.coorumConfirmTitle,
          accept: elem.dataset.coorumConfirmAccept,
          reject: elem.dataset.coorumConfirmReject,
          href: elem.href,
          method: elem.dataset.method,
          remote: elem.dataset.remote,
        })
        event.preventDefault()
        event.stopPropagation()
      });
    });
  }

  static confirm({
    message,
    title = null,
    accept = null,
    reject = null,
    href = null,
    method = null,
    remote = null,
    acceptCallback = null,
    rejectCallback = null,
  }) {
    const $confirmDialog = $('.coorum-confirm--modal') // ダイアログ
    message ||= ''
    title ||= ''
    accept ||= 'OK'
    reject ||= 'キャンセル'
    href ||= "#close:modal"
    remote ||= false
    $confirmDialog.find('.coorum-confirm--modal__body pre').text(message)
    $confirmDialog.find('.coorum-confirm--modal__header').text(title)
    const $rejectBtn = $confirmDialog.find('#confirm-reject-btn')
    $rejectBtn.text(reject)
    $rejectBtn.attr("onclick", rejectCallback)
    const $acceptBtn = $confirmDialog.find("#confirm-accept-btn")
    $acceptBtn.text(accept)
    $acceptBtn.on("click", acceptCallback)
    $acceptBtn.attr("href", href)
    !method ? $acceptBtn.removeAttr("data-method") : $acceptBtn.attr("data-method", method)
    $acceptBtn.attr("data-remote", remote)
    $confirmDialog.modal('show')

    if (remote) {
      $acceptBtn.on("click", (event) => {
        // NOTE: $confirmDialog.modal('hide') Bootstrap3.4ではmodal('hide')は動かない
        $rejectBtn.trigger({ type: "click" })
      });
    }
  }

}
