import Confirm from './components/Confirm';

class AlertContentTransition {
  constructor() {
    this.addListeners();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.addListeners();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  addListeners() {
    const search = document.querySelector('.c-Search_Form');
    const anchors = document.querySelectorAll(
      ['.st-Header a[href]:not(.st-Header_NewPost a)', '.footer a[href]', '.st-NotificationList_Item a[href]'].join(',')
    );

    anchors.forEach((anchor) => {
      if (!anchor.hasListener) {
        anchor.addEventListener('click', this.transitionConfirmation.bind(this));
        anchor.hasListener = true;
      }
    });

    if (search && !search.hasListener) {
      search.addEventListener('submit', this.transitionConfirmation.bind(this));
      search.hasListener = true;
    }
  }

  transitionConfirmation(e) {
    e.stopPropagation();
    e.preventDefault();

    const anchor = e.target.closest('a');
    if (!anchor) return;

    Confirm.confirm({
      message: 'このページを離れますか？\n離れると投稿は保存されません。',
      acceptCallback: () => { location.href = anchor.href },
      rejectCallback: ''
    });
  }
}

export default AlertContentTransition;
