import KeenSlider from 'keen-slider'
import Confirm from './Confirm'

const postCreationUrls = [
  '/questions/new',
  '/announcements/new',
  '/chats/new',
]

export default class Header {
  constructor() {
    this.sliderInstance = null
    this.initDom()
    this.setEvents()
  }

  initDom() {
    this.$html = $('html')
    this.$initiator = $('.js-Header')
    this.$overlay = this.$initiator.find('.st-Header_Overlay')
    this.$searchWindow = this.$initiator.find('.c-Search')
    this.$navigationContainer = this.$initiator.find('.st-Header_Nav')
    this.$navigation = this.$initiator.find('.st-Nav')
    this.$navigationItems = this.$initiator.find('.st-Nav_Item')
    this.$AdvancedSearchLink = this.$initiator.find('.st-AdvancedSearch_Link')
    this.$navigationGrouped = this.$navigationItems.filter('[data-expend]')

    this.$burger = this.$initiator.find('[data-header-burger]')
    this.$drawer = this.$initiator.find('[data-header-drawer]')
    this.$search = this.$initiator.find('[data-header-search]')
    this.$menuNext = this.$initiator.find('[data-menu-arrow-next]')
    this.$menuPrev = this.$initiator.find('[data-menu-arrow-prev]')
  }

  setEvents() {
    this.$burger.on('click', () => {
      this.drawerOpen()
    })

    this.$overlay.on('click', () => {
      this.drawerClose()
    })

    if (this.$searchWindow.length > 0) {
      $(document).on('click', (e) => {
        this.searchToggle(e)
      })
    }

    if (this.$navigationGrouped.length > 0) {
      this.$navigationGrouped.each((i, elem) => {
        $(elem).on('click', () => {
          this.navGroupExpend(elem)
        })
      })
    }

    if (this.$AdvancedSearchLink.length > 0) {
      $('.js-AdvancedSearch_Link').on('click', (e) =>{
        if (postCreationUrls.some((path) => location.pathname.includes(path))) {
          Confirm.confirm({
            message: 'このページを離れますか？\n離れると投稿は保存されません。',
            acceptCallback: () => this.setKeyword(e.currentTarget)
          });
        } else {
          this.setKeyword(e.currentTarget)
        }
      })
    }

    this.stretch()
    window.addEventListener('resize', () => {
      this.stretch()
    })
  }

  remainingWidth() {
    this.$navigation.get(0).dataset.stretch = 'false'
    return this.$navigationItems
      .map((i, _) => this.$navigationItems.eq(i).outerWidth(true))
      .toArray()
      .reduce((sum, elem) => sum - elem, this.$navigationContainer.width())
  }

  stretch() {
    if (this.$navigationItems.length < 1) return

    const remainingWidth = this.remainingWidth()

    if (remainingWidth > 0) {
      if (remainingWidth < 320) {
        this.$navigation.get(0).dataset.stretch = true
      }

      if (this.sliderInstance !== null) {
        this.sliderInstance.destroy()
      }
    } else {
      if (window.innerWidth >= 769 && this.sliderInstance === null) {
        this.sliderInstance = this.setSliderMenu()
      }
    }
  }

  searchToggle({ target }) {
    const $t = $(target)
    const hide = () => {
      this.$searchWindow.get(0).dataset.expend = false
      this.$html.removeClass('-search-locked')
    }

    if (
      !$t.closest('[data-header-search]').length &&
      !$t.closest('.c-Search').length
    ) {
      hide()
    } else if ($t.closest('[data-header-search]').length) {
      if (this.$searchWindow.get(0).dataset.expend === 'false') {
        this.$searchWindow.get(0).dataset.expend = true
        this.$html.addClass('-search-locked')
      } else {
        hide()
      }
    } else if ($t.closest('[data-header-search-close]').length) {
      if (!this.$searchWindow.is(':hidden')) {
        hide()
      }
    }
  }

  setSliderMenu() {
    const updateClasses = ({ progress }) => {
      progress === 0 // 最初のスライドの場合
        ? this.$menuPrev.attr('data-disabled', true)
        : this.$menuPrev.attr('data-disabled', false)
      progress === 1
        ? this.$menuNext.attr('data-disabled', true)
        : this.$menuNext.attr('data-disabled', false)
    }

    return new KeenSlider('.st-Nav', {
      selector: '.st-Nav_Item',
      disabled: true,
      breakpoints: {
        '(min-width: 768px)': {
          disabled: false,
          loop: false,
          mode: 'free-snap',
          rtl: false,
          slides: {
            perView: 'auto',
          },
        },
      },
      created: (instance) => {
        this.$menuNext.on('click', () => {
          instance.next()
        })
        this.$menuPrev.on('click', () => {
          instance.prev()
        })
        updateClasses(instance.track.details)
      },
      detailsChanged: (instance) => {
        updateClasses(instance.track.details)
      },
      destroyed: () => {
        this.sliderInstance = null
        this.$menuPrev.off('click').attr('data-disabled', true)
        this.$menuNext.off('click').attr('data-disabled', true)
      },
    })
  }

  navGroupExpend(currentElem) {
    const $data = $(currentElem).get(0).dataset
    if (JSON.parse($data.expend) === false) {
      this.$navigationGrouped.each((_, elem) => {
        $(elem).get(0).dataset.expend = false
      })
      $data.expend = true
    } else {
      $data.expend = false
    }
  }

  drawerOpen() {
    this.$html.addClass('-drawr-locked')
    this.$drawer.get(0).dataset.isOpen = true
  }

  drawerClose() {
    this.$html.removeClass('-drawr-locked')
    this.$drawer.get(0).dataset.isOpen = false
  }

  stringify(targetObject) {
    return Object.entries(targetObject).reduce((queries, current) => {
      let key = encodeURIComponent(current[0]);
      let value = encodeURIComponent(current[1]);
      return (queries += (queries === "" ? "" : "&") + `${key}=${value}`);
    }, "");
  }

  setKeyword(ele) {
    const searchForm = document.querySelector('.c-Search_Form')
    if (searchForm) {
      const _targetObject = {};
      const searchParams = new URLSearchParams(window.location.search);
      const nextQueryParams = {};
      if (searchForm["search_community_content[keyword]"] && searchForm["search_community_content[keyword]"].value != "") {
        _targetObject["search_community_content_advanced[keyword]"] = searchForm["search_community_content[keyword]"].value;
      }
      searchParams.forEach((val, key) => {
        nextQueryParams[key] = val;
      });
      for (const key in _targetObject) {
        nextQueryParams[key] = _targetObject[key];
      }
      delete nextQueryParams["page"];
      location.href = location.origin + "/community_contents/advanced_search" + `?${this.stringify(nextQueryParams)}`;
    }
  }
}
