class PreventErroneousSubmit {
  constructor(classname = '.js-prevent-submit') {
    this.elem = document.querySelector(classname)
    if (!this.elem || this.elem.length < 1) return

    this.inputs = this.elem.querySelectorAll('input, select')
    this.inputs.forEach((v, _) => {
      v.removeEventListener('keydown', this.keydownEvent, false)
      v.addEventListener('keydown', this.keydownEvent, false)
    })
  }

  keydownEvent(e) {
    if (
      e.keyIdentifier === 'U+000A' ||
      e.keyIdentifier === 'Enter' ||
      e.keyCode === 13
    ) {
      e.preventDefault()
      return false
    }
  }
}

export default PreventErroneousSubmit
