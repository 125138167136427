export default class PopUp {
  constructor() {
    this.initDom()
    this.setEvents()
  }

  initDom() {
    this.$initiator = $('.js-PopUp')
    if (this.$initiator.length < 1) return

    this.$sections = this.$initiator.find('[data-popup-section]')
  }

  setEvents() {
    if (this.$initiator.length < 1) return
    this.$sections.each((i, elem) => {
      const $buttonClose = $(elem).find('[data-popup-close]')
      const $buttonSubmit = $(elem).find('[data-popup-submit]')
      if ($buttonClose.length > 0) {
        $buttonClose.on('click', () => {
          this.close(elem)
        })
      }
      if ($buttonSubmit.length > 0) {
        $buttonSubmit.on('click', () => {
          this.submit($buttonSubmit.get(0))
        })
      }
    })
  }

  close(elem) {
    $(elem)
      .attr('data-popup-complete', '')
      .delay(400)
      .queue(() => {
        $(elem).remove()
      })
  }

  submit(elem) {
    $(elem).attr('data-popup-buttton-disabled', '')
  }
}
