import _debounce from 'lodash/debounce';

class Favorite {
  constructor(classname, option = {}, rootElement = document) {
    if (!classname) throw new Error('classname is required');
    if (!rootElement) throw new Error('rootElement is required');
    if (option && typeof option !== 'object') {
      throw new Error('option is not object');
    }

    this.classname = classname;
    this.rootElement = rootElement;
    this.option = Object.assign(
      {
        fn: {
          switch: undefined,
          formAction: undefined,
        },
        button: {
          toggleDisabled: true,
          toggleMethod: false,
        },
      },
      option
    );

    this.switch = this.option?.fn?.switch || this.switch;
    this.formAction = this.option?.fn?.formAction || this.formAction;
    this.buttons = this.rootElement.querySelectorAll(classname);

    if (!(this.formAction instanceof Function)) {
      throw new Error(`${this.formAction?.name} is not function`);
    }
    if (!(this.switch instanceof Function)) {
      throw new Error(`${this.switch?.name} is not function`);
    }
    if (this.buttons.length < 1) {
      return;
    }

    this.buttons.forEach((elem) => {
      this.switch(elem);
    });
  }

  switch(elem) {
    const { dataset } = elem;
    const { toggleDisabled } = this.option.button;
    if (!dataset) {
      throw new Error('data-method is required');
    }

    elem.addEventListener(
      'click',
      _debounce(() => {
        if (toggleDisabled) {
          elem.disabled = !elem.disabled;
        }
        return this.formAction(elem, this.option);
      }, 200)
    );
  }

  async formAction(elem, option) {
    const { dataset, disabled } = elem;
    const { toggleDisabled, toggleMethod } = option.button;
    const response = await fetch(
      `${dataset.url}?${dataset.key}_id=${dataset.value}`,
      {
        method: dataset.method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content'),
        },
      }
    );

    if (response.status !== 200) {
      throw new Error('response is not 200');
    }

    if (Array.isArray(toggleMethod) && toggleMethod.length > 0) {
      elem.dataset.method = dataset.method === toggleMethod[0] ? toggleMethod[1] : toggleMethod[0];
    }

    if (toggleDisabled) {
      elem.disabled = !disabled;
    }
  }

  // 複雑すぎるので外に出す
  indexManipulation() {
    const body = this.rootElement.querySelector('.js-favorite-post-body');

    if (body) {
      const content = document.createElement('div');
      const h4 = document.createElement('h4');

      content.classList.add('mypage-Article_Content');
      h4.classList.add('mypage-Article_Label');
      h4.textContent = '投稿がありません';

      content.appendChild(h4);
      body.innerHTML = '';
      body.appendChild(content);
    }
  }
}

class FavoriteModal {
  constructor(classname, fn = undefined) {
    if (!classname) throw new Error('classname is required');

    const buttons = document.querySelectorAll(classname);
    if (buttons.length < 1) return;

    if (fn instanceof Function) {
      this.toggle = fn;
    }

    buttons.forEach((button) => {
      if (button.dataset?.targetModal === undefined) {
        throw new Error('data-target-modal is required');
      }

      const modal = document.querySelector(`[data-modal-name="${button.dataset?.targetModal}"]`);

      button.addEventListener('click', (e) => {
        e.stopImmediatePropagation();
        this.toggle(modal, button);
      });
    });
  }

  toggle(modal) {
    modal.hasAttribute('open')
      ? modal.removeAttribute('open')
      : modal.setAttribute('open', '');
  }
}

class FavoriteRemoveModal extends FavoriteModal {
  toggle(modal, favoriteButton) {
    const { actionButton } = modal.dataset;
    const { url } = favoriteButton.dataset;
    if (actionButton === undefined && url === undefined) {
      throw new Error('data-action-button and data-url required');
    }

    const removeButton = modal.querySelector(`[data-button-name="${actionButton}"]`);
    if (removeButton === null) {
      throw new Error('removeButton is not found');
    }

    removeButton.href = url;
    modal.setAttribute('open', '');
  }
}

class FavoriteGroupingModal extends FavoriteModal {
  async toggle(modal, favoriteButton) {
    // モーダル側のアクションボタン
    const { actionButton } = modal.dataset;
    if (actionButton === undefined) {
      throw new Error('data-action-button is required');
    }
    const { favoriteId, groups } = favoriteButton.dataset;
    if (favoriteId === undefined) {
      throw new Error('data-favorite-id is required');
    }
    const checkboxes = modal.querySelectorAll('[data-input-name="grouping-input"]');
    const url_names = JSON.parse(groups).map((group) => group.url_name);
    const group_ids = [];

    if (Array.isArray(url_names) === false) {
      throw new Error('url_names is not array');
    }

    checkboxes.forEach((checkbox) => {
      if (url_names.includes(checkbox.value)) {
        checkbox.checked = true;
        group_ids.push(checkbox.value);
      } else {
        checkbox.checked = false;
      }
    });

    const groupingButton = modal.querySelector(`[data-button-name="${actionButton}"]`);
    if (groupingButton === null) {
      throw new Error('groupingButton is not found');
    }

    groupingButton.dataset.group_ids = JSON.stringify(group_ids);
    groupingButton.dataset.favoriteId = favoriteId;
    modal.setAttribute('open', '');
  }
}

class FavoriteAssignGroups {
  constructor(classname) {
    if (!classname) return;

    const button = document.querySelector(classname);
    if (!button) return;

    button.addEventListener(
      'click',
      _debounce(() => {
        if (!button.dataset.method) return;

        button.disabled = !button.disabled;
        return this.formAction(button);
      }, 200)
    );
  }

  async formAction(elem) {
    const { dataset, disabled } = elem;
    const params = new URLSearchParams();
    const groupIds = JSON.parse(dataset.groupIds);
    params.append('favorite[id]', dataset.favoriteId);
    groupIds.forEach((id) => {
      params.append('favorite[favorite_group_ids][]', id);
    });
    const response = await fetch(
      `${dataset.url}?${params.toString()}`,
      {
        method: dataset.method.toUpperCase(),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content')
        }
      }
    );

    if (response.status !== 200) {
      throw new Error('response is not 200');
    }

    const { groups } = await response.json();

    const favorite = document.querySelector(`[data-target-modal="grouping-modal"][data-favorite-id="${dataset.favoriteId}"]`);
    favorite.dataset.groups = JSON.stringify(groups);

    const modal = document.querySelector('[data-modal-name="grouping-modal"]');
    modal.removeAttribute('open');
    elem.disabled = !disabled;

    if (favorite.dataset?.closest !== undefined) {
      const grouplist = favorite.closest('.mypage-FavoriteGroup');
      const groupitems = grouplist.querySelectorAll('.mypage-FavoriteGroup_Item:not(.js-action_btn)');
      groupitems.forEach((item) => item.remove());
      groups.forEach((group) => {
        const item = document.createElement('li');
        const label = document.createElement('span');
        item.classList.add('mypage-FavoriteGroup_Item');
        label.classList.add('mypage-FavoriteGroup_Button');
        label.textContent = group.name;
        item.appendChild(label);
        grouplist.prepend(item);
      });
    }
  }
}

class FavoriteMyPage {
  constructor(classname = '') {
    // お気に入りの削除イベント
    this.addFavoriteEvents(classname);
  }

  addFavoriteEvents(classname) {
    if (classname === undefined) {
      throw new Error('classname is required');
    }

    new Favorite(classname, {
      fn: {
        formAction: this.removeFavoriteAction.bind(this),
      }
    });
  }

  async removeFavoriteAction(elem, option) {
    const { dataset, disabled } = elem;
    const { toggleDisabled } = option.button;
    const response = await fetch(
      `${dataset.url}?${dataset.key}_id=${dataset.value}`,
      {
        method: dataset.method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content'),
        },
      }
    );

    if (response.status !== 200) {
      throw new Error('response is not 200');
    }

    if (dataset?.targetModal === undefined) {
      throw new Error('data-target-modal is required');
    }

    const modal = document.querySelector(`[data-modal-name="${dataset.targetModal}"]`);
    if (modal === null) {
      throw new Error('modal is not found');
    }

    modal.removeAttribute('open');

    if (toggleDisabled) {
      elem.disabled = !disabled;
    }

    this.removeFavoriteDOM(elem);
  }

  removeFavoriteDOM(relative) {
    const { dataset } = relative;
    if (dataset?.key === undefined || dataset?.value === undefined) {
      throw new Error('data-key or data-value is required');
    }

    const target = document.querySelector([
      dataset.removeClass,
      `[data-value="${dataset.value}"]`,
      `[data-key="${dataset.key}"]`,
    ].join(''));

    dataset.value = '';
    dataset.key = '';

    if (target === null) {
      throw new Error('target is not found');
    }

    const articles = document.querySelectorAll(target.dataset.article);
    if (articles.length < 1) {
      throw new Error('articles is not found');
    }

    setTimeout(() => {
      articles.forEach((article) => article.remove());
      const panels = document.querySelectorAll('[data-favorite-panel]');
      if (panels.length < 1) {
        throw new Error('panels is not found');
      }

      panels.forEach((panel) => {
        if (panel.children.length < 1) {
          const article = document.createElement('article');
          const content = document.createElement('div');
          const h4 = document.createElement('h4');

          article.classList.add('mypage-Article');
          content.classList.add('mypage-Article_Content');
          content.classList.add('-favorite');
          h4.classList.add('mypage-Article_Isnt');
          h4.textContent = 'お気に入りがありません';
          content.appendChild(h4);
          article.appendChild(content);

          panel.nextElementSibling.remove();
          panel.classList.remove(...panel.classList);
          panel.classList.add('mypage-Panel_Body');
          panel.appendChild(article);
        }
      });

    }, 400);
  }
}

class FavoriteCreateGroup {
  constructor() {
    const submitButton = document.querySelector('.js-create-group');
    if (!submitButton) return;
    submitButton.addEventListener(
      'click',
      _debounce(() => {
        if (!submitButton.dataset.method) return;

        submitButton.disabled = !submitButton.disabled;
        return this.formAction(submitButton);
      }, 200)
    );

    this.showFormButton = document.querySelector('.js-show_group_name_form');
    this.createGroupForm = document.querySelector('.js-create_group_form');
    this.showFormButton.addEventListener(
      'click',
      () => {
        this.showFormButton.classList.add('hidden');
        this.createGroupForm.classList.remove('hidden');
      });
  }

  async formAction(elem) {
    const { dataset, disabled } = elem;
    const params = new URLSearchParams();
    const create_group_name_input = document.getElementById('js-create_group_name_input');
    params.append('favorite_group[name]', create_group_name_input.value);

    const response = await fetch(
      `${dataset.url}?${params.toString()}`,
      {
        method: dataset.method.toUpperCase(),
        headers: {
          'Accept': 'application/json',
          'X-CSRF-Token': document
            .querySelector('meta[name=csrf-token]')
            .getAttribute('content')
        }
      }
    );

    const validation_errors = document.querySelector('.js-validation_errors');
    validation_errors.innerHTML = '';

    // バリデーションエラー
    if (response.status === 400) {
      const { validation_errors_html } = await response.json();
      validation_errors.innerHTML = validation_errors_html;
      elem.disabled = !disabled;
      return;
    }

    if (response.status !== 200) {
      throw new Error('response is not 200');
    }

    const { favorite_group_list_html, is_max_group_reached } = await response.json();
    create_group_name_input.value = '';
    // 現在のチェック状態を取得
    const currentCheckedItems = document.querySelectorAll('[data-input-name="grouping-input"]:checked');
    // チェックボックスの再作成
    const favoriteGroupsList = document.querySelector('.js-favorite_group_list');
    favoriteGroupsList.innerHTML = favorite_group_list_html;
    // チェック状態を再設定
    currentCheckedItems.forEach((element) => {
      document.getElementById(element.id).checked = true;
    });
    // イベントの再設定
    new FavoriteGroupCheckButtonState('.js-select-group[data-checked-items=".peer"]');

    if (!is_max_group_reached) {
      this.showFormButton.classList.remove('hidden');
    }

    const createdGroupList = document.querySelector('.js-created_group_list');
    createdGroupList.classList.remove('hidden');

    const noGroupAnnotation = document.querySelector('.js-no-group-annotation');
    if (noGroupAnnotation) {
      noGroupAnnotation.classList.add('hidden');
    }

    const selectGroupButton = document.querySelector('.js-select-group');
    selectGroupButton.classList.remove('u-bg-gray-2');
    selectGroupButton.classList.remove('u-text-gray-6');
    selectGroupButton.classList.remove('u-border-gray-2');
    selectGroupButton.classList.add('bg-[--main-color]');
    selectGroupButton.classList.add('text-[--button-text-color]');
    selectGroupButton.disabled = false;

    this.createGroupForm.classList.add('hidden');
    elem.disabled = !disabled;
  }
}

class FavoriteGroupCheckButtonState {
  constructor(classname) {
    if (!classname) return;

    this.button = document.querySelector(classname);
    if (!this.button || !this.button.dataset?.checkedItems) return;

    this.checkedItems = document.querySelectorAll(this.button.dataset?.checkedItems);
    if (!this.checkedItems) return;

    this.changeButtonState();

    this.checkedItems.forEach((item) => {
      item.addEventListener('change', () => {
        this.changeButtonState();
      });
    });
  }

  changeButtonState() {
    const items = [...this.checkedItems];
    const ids = items.filter((item) => item.checked === true).map((item) => item.value);
    this.button.dataset.groupIds = JSON.stringify(ids);
  }
}

export {
  Favorite,
  FavoriteMyPage,
  FavoriteModal,
  FavoriteRemoveModal,
  FavoriteGroupingModal,
  FavoriteAssignGroups,
  FavoriteCreateGroup,
  FavoriteGroupCheckButtonState
};
