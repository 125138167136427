import Rails from '@rails/ujs';
import 'core-js/stable';
import jQuery from 'jquery';
import 'regenerator-runtime/runtime';

Rails.start();
window.$ = window.jQuery = jQuery;

require('bootstrap-sass/assets/javascripts/bootstrap.min.js');
require('bootstrap-sass/assets/javascripts/bootstrap/tab');
require('bootstrap-sass/assets/javascripts/bootstrap/tooltip');
require('jscroll');
require('jquery-modal');
import 'admin-lte/bower_components/bootstrap-daterangepicker/daterangepicker';
import AlertContentTransition from './alertContentTransition';
import { Favorite } from './components/Favorite';
import Header from './components/Header';
import Notification from './components/Notification';
import PopUp from './components/PopUp';
import TabSlider from './components/TabSlider';
import PreventErroneousSubmit from './prevent_erroneous_submit';
import Confirm from './components/Confirm';

if (!window?.Coorum) {
  window.Coorum = {};
}
window.Coorum.PreventErroneousSubmit = PreventErroneousSubmit;
window.Coorum.AlertContentTransition = AlertContentTransition;
window.Coorum.Favorite = Favorite;

// jQuery-UIを読み込んでいるページにおいて、jQuery-UIのtooltipとbootstrapのtooltipが競合するため、bootstrapのtooltipを別名で利用する
// jQuery-UIを読み込んでいないページでは、bootstrapのtooltipをそのまま利用する
const bootstrapTooltip = $.fn.tooltip.noConflict();
$.fn.bstooltip = bootstrapTooltip;

document.addEventListener('DOMContentLoaded', () => {
  new Header();
  new Notification();
  new TabSlider();
  new PopUp();
  new Confirm();

  // NOTE: jquery-modalを利用している箇所で必要らしい
  if ($('.js-Header').length > 0) {
    $(document).on('click', function (e) {
      if (!$(e.target).closest('.js-menu_contents, .js-menu_open').length) {
        $('body').attr({ style: 'overflow: unset;' });
      }
    });
  }

  $('.modal').on($.modal.BEFORE_BLOCK, (e, modal) => {
    const blockerClassName = modal?.$elm[0]?.dataset?.modalBlockerClassName;
    blockerClassName &&
      (modal.options.blockerClass = `jquery-modal ${blockerClassName}`);
  });

  updateHeightPost();
});

window.addEventListener('load', () => {
  new Coorum.PreventErroneousSubmit();
});

$('#privacypolicy a').click(function (e) {
  e.preventDefault();
  $(this).tab('show');
});

$(function () {
  $('.accordion-content').hide();

  $('.accordion-button').click(function () {
    $(this).next().slideToggle();
  });

  $('.header--sp__menu').outerHeight(
    $(window).outerHeight() - $('.header--sp__user').outerHeight()
  );
});

$(function () {
  $(document).on('click', '.js-download_file', function () {
    downloadFile($(this).data('fileUrl'), $(this).data('fileName'));
  });
});

function downloadFile(url, filename) {
  'use strict';

  // XMLHttpRequestオブジェクトを作成する
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob'; // Blobオブジェクトとしてダウンロードする
  xhr.onload = function (oEvent) {
    // ダウンロード完了後の処理を定義する
    var blob = xhr.response;
    if (window.navigator.msSaveBlob) {
      // IEとEdge
      window.navigator.msSaveBlob(blob, filename);
    } else {
      // それ以外のブラウザ
      // Blobオブジェクトを指すURLオブジェクトを作る
      var objectURL = window.URL.createObjectURL(blob);
      // リンク（<a>要素）を生成し、JavaScriptからクリックする
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = objectURL;
      link.download = filename;
      link.click();
      document.body.removeChild(link);
    }
  };
  // XMLHttpRequestオブジェクトの通信を開始する
  xhr.send();
}

function updateHeightPost() {
  // モバイルアプリより開いている時のみ有効になります
  if (typeof updateHeight !== 'undefined' && updateHeight?.postMessage) {
    updateHeight.postMessage(document.body.scrollHeight);
  }
}

window.updateTokenAtResume = (token) => {
  $.ajax({
    type: 'POST',
    url: '/devices',
    dataType: 'json',
    data: {
      device: { token: token },
    },
  });
};

window.getNewPosts = () => {
  $.ajax({
    type: 'GET',
    url: '/community/new_posts',
    dataType: 'json',
  }).done((response) => {
    if (typeof getNewPostsChannel === 'undefined') return;
    getNewPostsChannel.postMessage(JSON.stringify(response));
  });
};

window.getNewComments = () => {
  $.ajax({
    type: 'GET',
    url: '/community/new_comments',
    dataType: 'json',
  }).done((response) => {
    if (typeof getNewCommentsChannel === 'undefined') return;
    getNewCommentsChannel.postMessage(JSON.stringify(response));
  });
};
