import KeenSlider from 'keen-slider'
export default class TabSlider {
  constructor() {
    this.sliderInstance = null
    this.initDom()
    this.setEvents()
  }

  initDom() {
    this.$html = $('html')
    this.$initiator = $('.p-category--timeline-tab')
    this.$navigationContainer = this.$initiator
    this.$navigation = this.$initiator.find('.p-category--timeline-tab-items')
    this.$navigationItems = this.$initiator.find('.p-category--timeline-tab-items>li')
    this.$menuNext = this.$initiator.find('[data-menu-arrow-next]')
    this.$menuPrev = this.$initiator.find('[data-menu-arrow-prev]')
  }

  setEvents() {
    this.stretch()
    window.addEventListener('resize', () => {
      this.stretch()
    })
  }

  remainingWidth() {
    this.$navigation.get(0).dataset.stretch = 'false'
    const result = this.$navigationItems
      .map((i, _) => this.$navigationItems.eq(i).outerWidth(true))
      .toArray()
      .reduce((sum, elem) => sum - elem, this.$navigationContainer.width())
    return result;
  }

  stretch() {
    if (this.$navigationItems.length < 1) return
    const remainingWidth = this.remainingWidth()

    if (window.innerWidth >= 769) {
      if (this.sliderInstance !== null) {
        this.sliderInstance.destroy()
      }
    } else {
      if (remainingWidth > 0) {
        this.$navigation.get(0).dataset.stretch = true

        if (this.sliderInstance !== null) {
          this.sliderInstance.destroy()
        }
      } else {
        if (this.sliderInstance === null) {
          this.sliderInstance = this.setSliderMenu()
        }
      }
    }
  }

  setSliderMenu() {
    const updateClasses = ({ progress }) => {
      progress === 0 || isNaN(progress) // 最初のスライドの場合
        ? this.$menuPrev.attr('data-disabled', true)
        : this.$menuPrev.attr('data-disabled', false)
      progress === 1 || isNaN(progress)
        ? this.$menuNext.attr('data-disabled', true)
        : this.$menuNext.attr('data-disabled', false)
    }

    return new KeenSlider('.p-category--timeline-tab-items', {
      selector: '.p-category--timeline-tab-items>li',
      disabled: false,
      loop: false,
      mode: 'free-snap',
      rtl: false,
      slides: {
        perView: 'auto',
      },
      created: (instance) => {
        this.$menuNext.on('click', () => {
          instance.next()
        })
        this.$menuPrev.on('click', () => {
          instance.prev()
        })
        updateClasses(instance.track.details)
      },
      detailsChanged: (instance) => {
        updateClasses(instance.track.details)
      },
      destroyed: () => {
        this.sliderInstance = null
        this.$menuPrev.off('click').attr('data-disabled', true)
        this.$menuNext.off('click').attr('data-disabled', true)
      },
    })
  }

  navGroupExpend(currentElem) {
    const $t = $(currentElem)
    if ($t.get(0).dataset.expend == 'false') {
      this.$navigationGrouped.each((_, elem) => {
        $(elem).get(0).dataset.expend = false
      })
      $t.get(0).dataset.expend = true
    } else {
      $t.get(0).dataset.expend = false
    }
  }

}
